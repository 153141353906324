import { useCallback } from 'react';
import {
  useLocalizedVariant,
  useProductByHandle,
} from '@backpackjs/storefront';

import { Image, Link } from '~/snippets';
import { useDataLayerActions, useVariantPrices } from '~/hooks';

export function SearchItem({ closeSearch, index, item }) {
  const { product } = useProductByHandle({ handle: item.handle });
  const firstVariant = product?.variants?.[0];
  const { price, compareAtPrice } = useVariantPrices({
    variant: firstVariant,
  });
  const { sendClickProductItemEvent } = useDataLayerActions();
  const { localized } = useLocalizedVariant({ variant: firstVariant });

  const handleClick = useCallback(() => {
    sendClickProductItemEvent({
      isSearchResult: true,
      listIndex: index,
      localized,
      product,
      selectedVariant: firstVariant,
    });
    closeSearch();
  }, [index, localized, product?.id]);

  const url = `/products/${item.handle}`;
  const color = item.optionsMap?.Color?.[0];
  const image = item.featuredImage;

  return (
    <Link
      aria-label={`View ${item.title}`}
      className="relative grid grid-cols-[5.5rem_1fr] items-center gap-3"
      href={url}
      onClick={handleClick}
    >
      <div
        className="bg-offWhite"
        style={{
          aspectRatio:
            image?.width && image?.height
              ? image.width / image.height
              : 'var(--product-image-aspect-ratio)',
        }}
      >
        {image?.src && (
          <Image
            alt={item.title}
            height={Math.floor(88 / (image.width / image.height))}
            src={image.src}
            width="88"
          />
        )}
      </div>

      <div className="flex flex-col justify-between gap-3">
        <div>
          <h4 className="text-title-h6">{item.title}</h4>

          <p className="min-h-[1.25rem] text-sm text-mediumGray">{color}</p>
        </div>

        <div className="flex flex-wrap gap-x-1.5">
          {compareAtPrice && (
            <p className="text-sm text-mediumGray line-through">
              {compareAtPrice}
            </p>
          )}
          <p className="min-h-[1.25rem] text-sm">{price}</p>
        </div>
      </div>
    </Link>
  );
}

SearchItem.displayName = 'SearchItem';
