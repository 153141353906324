import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

const PAGE_TYPES = {
  '/': 'home',
  '/account': 'customersAccount',
  '/account/activate': 'customersActivateAccount',
  '/account/address-book': 'customersAddresses',
  '/account/login': 'customersLogin',
  '/account/orders/': 'customersOrders',
  '/account/register': 'customersRegister',
  '/account/reset-password': 'customersResetPassword',
  '/articles': 'article',
  '/blogs': 'blog',
  '/cart': 'cart',
  '/collections': 'collection',
  '/not-found': 'notFound',
  '/pages': 'page',
  '/pages/404': 'notFound',
  '/pages/privacy-policy': 'policy',
  '/pages/search': 'search',
  '/products': 'product',
};

export function useShopifyAnalyticsPageView({
  basePayload,
  collection,
  product,
  sendShopifyAnalyticsEvent,
}) {
  const pathnameRef = useRef(null);
  const { asPath, query } = useRouter();
  const pathname = asPath.split('?')[0];
  const searchString = pathname === '/pages/search' && query.term;

  useEffect(() => {
    if (!basePayload || pathnameRef.current === pathname) return undefined;

    sendShopifyAnalyticsEvent(
      // payload
      {
        ...basePayload,
        canonicalUrl: `${process.env.SITE_URL}${
          window.location.pathname === '/' ? '' : window.location.pathname
        }`,
        pageType: pathname.startsWith('/account/orders/')
          ? PAGE_TYPES['/account/orders/']
          : PAGE_TYPES[pathname] ||
            PAGE_TYPES[pathname.split('/').slice(0, -1).join('/')] ||
            '',
        ...(searchString ? { searchString } : null),
        ...(collection
          ? { collectionHandle: collection.handle, resourceId: collection.id }
          : null),
        ...(product
          ? {
              totalValue: parseFloat(product.priceRange.min),
              resourceId: product.id,
              products: [
                {
                  productGid: product.id,
                  name: product.title,
                  brand: product.vendor,
                  price: product.priceRange.min,
                  category: product.productType,
                },
              ],
            }
          : null),
      },
      // event name
      'PAGE_VIEW'
    );

    pathnameRef.current = pathname;
    return () => {
      pathnameRef.current = pathname;
    };
  }, [basePayload, pathname, searchString]);
}
