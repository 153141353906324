import { useMemo } from 'react';

import { Image, Link } from '~/snippets';

export function BlogGridItem({ article }) {
  const date = useMemo(() => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return new Date(
      article.publishedAt || article.createdAt
    ).toLocaleDateString('en-US', options);
  }, [article.createdAt, article.publishedAt]);

  const url = `/articles/${article.handle}`;

  return (
    <div>
      <Link aria-label={article.title} href={url} tabIndex="-1">
        <div className="relative mb-4 aspect-[3/2] bg-offWhite">
          {article.seo?.image && (
            <Image
              alt={article.title}
              fill
              sizes="(min-width: 1440px) 1200px, (min-width: 768px) 50vw, 100vw"
              src={article.seo.image}
            />
          )}
        </div>
      </Link>

      <div className="flex flex-col items-start gap-2">
        <p className="text-sm text-mediumGray">
          {article.author ? `${article.author} | ` : ''}
          {date}
        </p>

        <Link aria-label={article.title} href={url} tabIndex="-1">
          <h3 className="text-title-h4">{article.title}</h3>
        </Link>

        {article.excerpt && (
          <p
            className="overflow-hidden text-sm"
            style={{
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {article.excerpt}
          </p>
        )}

        <Link
          aria-label={`Read article ${article.title}`}
          className="text-label text-main-underline"
          href={url}
        >
          Read More
        </Link>
      </div>
    </div>
  );
}

BlogGridItem.displayName = 'BlogGridItem';
