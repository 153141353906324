import { useRef } from 'react';

import { Badges, Image, Spinner } from '~/snippets';
import { ProductItemVideo } from './ProductItemVideo';
import { useProductItemMedia } from './useProductItemMedia';

export function ProductItemMedia({ selectedProduct, selectedVariant }) {
  const hoverVideoRef = useRef(null);

  const { primaryMedia, hoverMedia } = useProductItemMedia({
    selectedProduct,
    selectedVariant,
  });

  const { width, height } = { ...primaryMedia?.preview?.image };

  return (
    <div
      className="group/media relative bg-offWhite"
      // for a static/consistent aspect ratio, delete style below and add 'aspect-[var(--product-image-aspect-ratio)]' to className
      style={{
        aspectRatio:
          width && height
            ? width / height
            : 'var(--product-image-aspect-ratio)',
      }}
      onMouseEnter={() => {
        if (hoverMedia?.mediaContentType !== 'VIDEO') return;
        hoverVideoRef.current.play();
      }}
      onMouseLeave={() => {
        if (hoverMedia?.mediaContentType !== 'VIDEO') return;
        hoverVideoRef.current.pause();
      }}
    >
      {/* media w/o hover element */}
      {primaryMedia &&
        !hoverMedia &&
        (primaryMedia.mediaContentType === 'VIDEO' ? (
          <ProductItemVideo autoPlay media={primaryMedia} />
        ) : (
          <Image
            alt={selectedProduct?.title}
            fill
            sizes="(min-width: 768px) 33vw, 50vw"
            src={primaryMedia.image.src}
            srcSetSizes={[480, 960]}
          />
        ))}

      {/* media w/ hover element */}
      {primaryMedia && hoverMedia && (
        <>
          <div className="opacity-100 transition duration-300 md:group-hover/media:opacity-0">
            {primaryMedia.mediaContentType === 'VIDEO' ? (
              <ProductItemVideo autoPlay media={primaryMedia} />
            ) : (
              <Image
                alt={selectedProduct?.title}
                fill
                sizes="(min-width: 768px) 33vw, 50vw"
                src={primaryMedia.image.src}
                srcSetSizes={[480, 960]}
              />
            )}
          </div>

          <div className="hidden opacity-0 transition duration-300 md:block md:group-hover/media:opacity-100">
            {hoverMedia.mediaContentType === 'VIDEO' ? (
              <ProductItemVideo
                autoPlay={false}
                media={hoverMedia}
                ref={hoverVideoRef}
              />
            ) : (
              <Image
                alt={selectedProduct?.title}
                fill
                sizes="(min-width: 768px) 33vw, 50vw"
                src={hoverMedia.image.src}
                srcSetSizes={[480, 960]}
              />
            )}
          </div>
        </>
      )}

      {/* loader */}
      {!primaryMedia && (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-gray">
          <Spinner width="20" />
        </div>
      )}

      <div className="pointer-events-none absolute left-0 top-0 z-[1] p-2.5 xs:p-3 xl:p-4">
        <Badges
          className="max-xl:text-label-sm gap-2 xs:gap-2 [&_div]:max-xl:px-1.5 [&_div]:max-xl:py-0.5"
          isDraft={selectedProduct?.status === 'DRAFT'}
          tags={selectedVariant?.product?.tags}
        />
      </div>
    </div>
  );
}

ProductItemMedia.displayName = 'ProductItemMedia';
