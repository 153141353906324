import { useMemo } from 'react';

import { Image, Link, QuantitySelector, Spinner, Svg } from '~/snippets';
import { useCartItem } from './useCartItem';
import { useCartItemImage } from './useCartItemImage';
import { useCartItemPrices } from './useCartItemPrices';

export function CartItem({ closeCart, item }) {
  const { discountAllocations, quantity, variant } = item;

  const {
    isUpdatingItem,
    isRemovingItem,
    handleDecrement,
    handleIncrement,
    handleRemove,
  } = useCartItem({ item });

  const { price, compareAtPrice } = useCartItemPrices({ item });

  const image = useCartItemImage({ item });

  const url = useMemo(() => {
    return `/products/${variant.product.handle}?variant=${variant.id
      .split('/')
      .pop()}`;
  }, [variant.id]);

  const discountTitles = useMemo(() => {
    if (!discountAllocations) return null;
    return discountAllocations.map(({ title }) => title).filter(Boolean);
  }, [discountAllocations]);

  return (
    <div className="relative grid grid-cols-[auto_1fr] items-center gap-3 p-4 ">
      <Link
        aria-label={`View ${variant.product.title}`}
        href={url}
        onClick={closeCart}
        tabIndex="-1"
      >
        {image?.src && (
          <Image
            alt={variant.product.title}
            className="bg-offWhite"
            crop="center"
            height={Math.floor(88 / (image.width / image.height))}
            src={image.src}
            width="88"
          />
        )}
      </Link>

      <div className="flex min-h-[6.25em] flex-col justify-between gap-4">
        <div className="relative pr-6">
          <Link
            aria-label={`View ${variant.product.title}`}
            href={url}
            onClick={closeCart}
          >
            <h3 className="text-title-h6">{variant.product.title}</h3>
          </Link>

          {variant.title !== 'Default Title' && (
            <p className="text-sm text-mediumGray">{variant.title}</p>
          )}

          <button
            aria-label={`Remove ${variant.product.title} from cart`}
            className="absolute right-0 top-0 w-3"
            onClick={handleRemove}
            type="button"
          >
            <Svg
              src="/svgs/close.svg#close"
              title="Close"
              viewBox="0 0 24 24"
            />
          </button>
        </div>

        <div className="flex items-end justify-between gap-3">
          <QuantitySelector
            handleDecrement={handleDecrement}
            handleIncrement={handleIncrement}
            isUpdating={isUpdatingItem}
            productTitle={variant.product.title}
            quantity={quantity}
          />

          <div className="flex flex-1 flex-col items-end pb-1">
            {discountTitles?.length > 0 &&
              discountTitles.map((title) => {
                return (
                  <p key={title} className="pb-1 text-xs text-primary">
                    {title}
                  </p>
                );
              })}

            <div className="flex flex-wrap justify-end gap-x-2">
              {compareAtPrice && (
                <p className="text-mediumGray line-through">{compareAtPrice}</p>
              )}
              <p>{price}</p>
            </div>
          </div>
        </div>
      </div>

      {isRemovingItem && (
        <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-[rgba(255,255,255,0.6)] text-mediumGray">
          <Spinner width="20" />
        </div>
      )}
    </div>
  );
}

CartItem.displayName = 'CartItem';
