import { useMemo } from 'react';
import { useSettings } from '@backpackjs/storefront';

import { AddToCart } from '~/snippets';
import { QuickShopOptions } from './QuickShopOptions';

export function QuickShop({
  enabledColorSelector,
  selectedProduct,
  selectedVariant,
}) {
  const settings = useSettings();
  const { quickShopMultiText, quickShopSingleText } = {
    ...settings?.collection?.productItem,
  };

  const qualifiesForQuickShop = useMemo(() => {
    if (!selectedProduct) return false;

    const initialOptions = selectedProduct.grouping?.isTransformed
      ? selectedProduct.grouping.options
      : selectedProduct.options;
    const options = enabledColorSelector
      ? initialOptions.filter((option) => option.name !== 'Color')
      : initialOptions;

    const hasOnlySingleValueOptions =
      options.every((option) => {
        return option.values.length === 1;
      }) || false;
    const hasOnlyOneOptionWithMultipleValues =
      options.reduce((acc, option) => {
        return acc + (option.values.length > 1 ? 1 : 0);
      }, 0) === 1 || false;
    const hasColorOptionWithMultipleValues = enabledColorSelector
      ? false
      : selectedProduct.grouping?.optionsMap?.Color?.length > 1 || false;

    return (
      (hasOnlySingleValueOptions || hasOnlyOneOptionWithMultipleValues) &&
      !hasColorOptionWithMultipleValues
    );
  }, [enabledColorSelector, selectedProduct?.id]);

  const hasOneVariant = selectedProduct?.variants?.length === 1;

  return qualifiesForQuickShop && selectedVariant ? (
    <div className="mt-5 hidden opacity-0 transition group-hover:opacity-100 md:block lg:mt-6">
      {hasOneVariant && (
        <AddToCart
          addToCartText={quickShopSingleText}
          className="btn-inverse-dark"
          selectedVariant={selectedVariant}
        />
      )}

      {!hasOneVariant && (
        <QuickShopOptions
          quickShopMultiText={quickShopMultiText}
          selectedProduct={selectedProduct}
          selectedVariant={selectedVariant}
        />
      )}
    </div>
  ) : null;
}

QuickShop.displayName = 'QuickShop';
