import {
  useCartTotals,
  useCartCount,
  useCartCheckout,
  useSettings,
} from '@backpackjs/storefront';

export function CartTotals() {
  const cartCount = useCartCount();
  const cartTotals = useCartTotals();
  const { cartCheckout } = useCartCheckout();
  const settings = useSettings();
  const { checkoutText, subtext, subtotalText } = { ...settings?.cart?.totals };

  return (
    <div
      className={`flex-col gap-4 border-t border-t-border p-4 ${
        cartCount ? 'flex' : 'hidden'
      }`}
    >
      <div>
        <div className="flex justify-between">
          <p className="font-bold">{subtotalText || 'Subtotal'}</p>
          <p>
            $
            {parseFloat(cartTotals?.total || '0')
              .toFixed(2)
              .replace('.00', '')}
          </p>
        </div>

        {subtext && <p className="mt-1 text-xs">{subtext}</p>}
      </div>

      <button
        aria-label="Go to checkout page"
        className="btn-primary w-full"
        onClick={cartCheckout}
        type="button"
      >
        {checkoutText || 'Checkout'}
      </button>
    </div>
  );
}

CartTotals.displayName = 'CartTotals';
