import { useState } from 'react';
import { useRouter } from 'next/router';
import { useSettings } from '@backpackjs/storefront';

const PROMOBAR_HEIGHT = 48;

export function usePromobar() {
  const settings = useSettings();
  const { asPath } = useRouter();

  const [promobarHidden, setPromobarHidden] = useState(null);
  // ensures promobar doesn't flash between pdp product grouping variant changes
  const [hidePromobarOnTransition] = useState(
    asPath.startsWith('/products') &&
      typeof window !== 'undefined' &&
      window.scrollY > PROMOBAR_HEIGHT
  );

  const promobar = settings?.header?.promobar;
  const promobarDisabled =
    !!promobar && (!promobar.enabled || !promobar.messages?.length);
  const mainClassName = promobarDisabled
    ? 'pt-[var(--header-height)]'
    : 'pt-[calc(var(--header-height)+var(--promobar-height))]';

  return {
    hidePromobarOnTransition:
      hidePromobarOnTransition &&
      typeof promobarHidden !== 'boolean' &&
      !!promobar?.autohide,
    mainClassName,
    promobarDisabled,
    promobarHeight: PROMOBAR_HEIGHT,
    promobarHidden,
    setPromobarHidden,
  };
}
