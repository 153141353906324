import { COLORS } from '~/settings/common';

export function Schema() {
  return {
    category: 'Text',
    label: 'Icon Row',
    key: 'icon-row',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/icon-row-preview.jpg?v=1675730317',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Icon Row Heading',
      },
      {
        label: 'Subtext',
        name: 'subtext',
        component: 'markdown',
        defaultValue:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      },
      {
        label: 'Icons',
        name: 'icons',
        component: 'group-list',
        itemProps: {
          label: '{{item.icon}}',
        },
        fields: [
          {
            label: 'Icon',
            name: 'icon',
            component: 'select',
            options: [
              { label: 'Shipping', value: 'shipping' },
              { label: 'Customer Service', value: 'customer-service' },
              { label: 'Warranty', value: 'warranty' },
              { label: 'Innovation', value: 'innovation' },
            ],
          },
          {
            label: 'Label',
            name: 'label',
            component: 'text',
          },
        ],
        defaultValue: [
          { icon: 'shipping', label: 'Free Shipping' },
          { icon: 'customer-service', label: 'Top Customer Service' },
          { icon: 'warranty', label: 'Lifetime Warranty' },
          { icon: 'innovation', label: 'Innovative Designs' },
        ],
        defaultItem: { icon: 'shipping', label: 'New Icon' },
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Background color, text color, icon color, full width',
        fields: [
          {
            label: 'Background Color',
            name: 'bgColor',
            component: 'select',
            options: COLORS,
          },
          {
            label: 'Text Color',
            name: 'textColor',
            component: 'select',
            options: COLORS,
          },
          {
            label: 'Icon Color',
            name: 'iconColor',
            component: 'select',
            options: COLORS,
          },
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: {
          bgColor: 'var(--off-white)',
          textColor: 'var(--text)',
          iconColor: 'var(--primary)',
          fullWidth: false,
        },
      },
    ],
  };
}
