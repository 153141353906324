import { useEffect, useMemo } from 'react';
import Script from 'next/script';

export function BlogSchemaMarkup({ blog = {}, pageUrl }) {
  const blogSchemaMarkup = useMemo(() => {
    return {
      '@context': 'https://schema.org',
      '@type': 'Blog',
      '@id': pageUrl,
      mainEntityOfPage: pageUrl,
      name: blog.seo?.title || '',
      description: blog.seo?.description || '',
      publisher: {
        '@type': 'Organization',
        '@id': process.env.SITE_URL,
        name: process.env.NEXT_PUBLIC_SITE_TITLE || '',
        logo: {
          '@type': 'ImageObject',
          '@id': process.env.NEXT_PUBLIC_SITE_LOGO || '',
          url: process.env.NEXT_PUBLIC_SITE_LOGO || '',
        },
      },
    };
  }, [blog, pageUrl]);

  useEffect(() => {
    const script = document.querySelector('#schema-markup-blog');
    if (!script) return;
    const blogSchemaMarkupJSON = JSON.stringify(blogSchemaMarkup);
    if (JSON.stringify(script.innerHTML) !== blogSchemaMarkupJSON) {
      script.innerHTML = blogSchemaMarkupJSON;
    }
  }, [pageUrl]);

  return (
    <Script
      id="schema-markup-blog"
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(blogSchemaMarkup),
      }}
    />
  );
}

BlogSchemaMarkup.displayName = 'BlogSchemaMarkup';
