import { useEffect, useMemo } from 'react';
import Script from 'next/script';

export function MainSchemaMarkup({ pageUrl }) {
  const mainSchemaMarkup = useMemo(() => {
    return {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: process.env.NEXT_PUBLIC_SITE_TITLE || '',
      url: pageUrl,
      logo: process.env.NEXT_PUBLIC_SITE_LOGO || '',
    };
  }, [pageUrl]);

  useEffect(() => {
    const script = document.querySelector('#schema-markup-org');
    if (!script) return;
    const mainSchemaMarkupJSON = JSON.stringify(mainSchemaMarkup);
    if (JSON.stringify(script.innerHTML) !== mainSchemaMarkupJSON) {
      script.innerHTML = mainSchemaMarkupJSON;
    }
  }, [pageUrl]);

  return (
    <Script
      id="schema-markup-org"
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(mainSchemaMarkup),
      }}
    />
  );
}

MainSchemaMarkup.displayName = 'MainSchemaMarkup';
