import { useEffect, useMemo } from 'react';
import Script from 'next/script';

export function CollectionSchemaMarkup({ collection = {}, pageUrl }) {
  const collectionSchemaMarkup = useMemo(() => {
    return {
      '@context': 'https://schema.org/',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name: process.env.NEXT_PUBLIC_SITE_TITLE || '',
          item: process.env.SITE_URL || '',
        },
        {
          '@type': 'ListItem',
          position: 2,
          name: collection.title || '',
          item: pageUrl,
        },
      ],
    };
  }, [collection.title, pageUrl]);

  useEffect(() => {
    const script = document.querySelector(
      '#schema-markup-collection-breadcrumb'
    );
    if (!script) return;
    const collectionSchemaMarkupJSON = JSON.stringify(collectionSchemaMarkup);
    if (JSON.stringify(script.innerHTML) !== collectionSchemaMarkupJSON) {
      script.innerHTML = collectionSchemaMarkupJSON;
    }
  }, [pageUrl]);

  return (
    <Script
      id="schema-markup-collection-breadcrumb"
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(collectionSchemaMarkup),
      }}
    />
  );
}

CollectionSchemaMarkup.displayName = 'CollectionSchemaMarkup';
