import { useCallback, useEffect, useMemo, useState } from 'react';

import { ColorVariantOption } from './ColorVariantOption';
import { fetchProductsFromHandles } from '~/utilities';

export function ColorVariantOptions({
  enabledColorNameOnHover,
  initialProduct,
  selectedVariant,
  setProductFromColorSelector,
  setVariantFromColorSelector,
  swatchesMap,
}) {
  const [maxCount, setMaxCount] = useState(7);
  const [productMapByColor, setProductMapByColor] = useState(null);
  const [variantMapByColor, setVariantMapByColor] = useState(null);

  const colorOptions = useMemo(() => {
    const originalColor = initialProduct.optionsMap?.Color?.[0];
    return initialProduct.grouping?.isTransformed
      ? [
          originalColor,
          ...initialProduct.grouping.optionsMap.Color.filter(
            (color) => color !== originalColor
          ),
        ]
      : initialProduct.optionsMap?.Color;
  }, [initialProduct.id]);

  const slicedColorOptions = colorOptions.slice(0, maxCount);
  const remainingColorCount = colorOptions.length - slicedColorOptions.length;

  const generateProductMapByColor = useCallback(async () => {
    if (!initialProduct.grouping?.isTransformed) return;
    let productHandles = initialProduct.grouping.products || [];
    if (initialProduct.grouping.subgroups?.length) {
      initialProduct.grouping.subgroups.forEach((subgroup) => {
        productHandles = [...productHandles, ...subgroup.products];
      });
    }
    const products = await fetchProductsFromHandles(productHandles);
    setProductMapByColor(
      products?.reduce((acc, groupProduct) => {
        return { ...acc, [groupProduct.optionsMap?.Color?.[0]]: groupProduct };
      }, {})
    );
  }, [initialProduct.id]);

  const generateVariantMapByColor = useCallback(() => {
    if (initialProduct.grouping?.isTransformed) return;
    setVariantMapByColor(
      initialProduct.variants?.reduce((acc, variant) => {
        const variantColor = variant.selectedOptionsMap?.Color;
        if (acc[variantColor]) return acc;
        return {
          ...acc,
          [variantColor]: variant,
        };
      }, {})
    );
  }, [initialProduct.id]);

  const handleOptionClick = useCallback(
    (color) => {
      if (initialProduct.grouping?.isTransformed) {
        if (!productMapByColor) return;
        setProductFromColorSelector(productMapByColor[color]);
        setVariantFromColorSelector(productMapByColor[color]?.variants?.[0]);
      } else {
        setProductFromColorSelector(initialProduct);
        setVariantFromColorSelector(variantMapByColor?.[color]);
      }
    },
    [initialProduct.id, productMapByColor, variantMapByColor]
  );

  useEffect(() => {
    generateProductMapByColor();
    generateVariantMapByColor();
  }, [initialProduct.id]);

  return (
    <ul className="flex flex-wrap gap-1">
      {slicedColorOptions.map((color, index) => {
        return (
          <li key={index}>
            <ColorVariantOption
              color={color}
              enabledColorNameOnHover={enabledColorNameOnHover}
              onClick={() => handleOptionClick(color)}
              selectedVariant={selectedVariant}
              swatchesMap={swatchesMap}
            />
          </li>
        );
      })}

      {remainingColorCount > 0 && (
        <li className="flex">
          <button
            aria-label={`Show ${remainingColorCount} more color options`}
            className="whitespace-nowrap text-2xs"
            onClick={() => setMaxCount(colorOptions.length)}
            type="button"
          >
            + {remainingColorCount} more
          </button>
        </li>
      )}
    </ul>
  );
}

ColorVariantOptions.displayName = 'ColorVariantOptions';
