import { Fragment } from 'react';
import { Image, Link } from '~/snippets';

export function ImageTile({ aspectRatio, content, item }) {
  const firstLink = item.buttons?.[0]?.link;
  const secondLink = item.buttons?.[1]?.link;

  return (
    <Link
      aria-label={firstLink?.text}
      className="w-full"
      href={content?.clickableImage ? firstLink?.url : ''}
      newTab={firstLink?.newTab}
      type={firstLink?.type}
    >
      <div className={`relative bg-offWhite ${aspectRatio}`}>
        {item.image?.src && (
          <Image
            alt={item.alt}
            className={`${item.position}`}
            fill
            sizes="(min-width: 1024px) 30vw, (min-width: 768px) 50vw, 100vw"
            src={item.image.src}
          />
        )}
      </div>

      <div
        className={`pointer-events-none absolute inset-0 flex h-full w-full p-6 text-center md:p-8 ${
          content?.darkOverlay ? 'bg-[rgba(0,0,0,0.2)]' : ''
        } ${content?.contentPosition}`}
      >
        <div className="pointer-events-auto flex flex-col gap-3 lg:gap-4">
          {item.heading && (
            <h3 className="text-xl text-white sm:text-2xl">{item.heading}</h3>
          )}

          {!content?.hideButtons && (firstLink?.text || secondLink?.text) && (
            <div className="flex flex-wrap justify-center gap-3">
              {item.buttons.slice(0, 2).map(({ link }, index) => {
                // hide second button if clickable image is enabled
                if (content?.clickableImage && index > 0) return null;

                return link?.text ? (
                  <Fragment key={index}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link
                      aria-label={link.text}
                      className={`${
                        index === 1
                          ? content?.secondaryButtonStyle
                          : content?.primaryButtonStyle
                      }`}
                      href={!content?.clickableImage ? link.url : ''}
                      newTab={link.newTab}
                      type={link.type}
                    >
                      {link.text}
                    </Link>
                  </Fragment>
                ) : null;
              })}
            </div>
          )}
        </div>
      </div>
    </Link>
  );
}

ImageTile.displayName = 'ImageTile';
