import { useEffect, useMemo } from 'react';
import Script from 'next/script';
import { useProductInventoryByHandle } from '@backpackjs/storefront';

const IN_STOCK = 'https://schema.org/InStock';
const OUT_OF_STOCK = 'https://schema.org/OutOfStock';

const getAvailability = ({ variant, inventory }) => {
  if (!variant) return null;
  const variantInventory = inventory?.variants?.[variant.id];
  if (!variantInventory) {
    return variant.availableForSale ? IN_STOCK : OUT_OF_STOCK;
  }
  return variantInventory.availableForSale ? IN_STOCK : OUT_OF_STOCK;
};

export function ProductSchemaMarkup({ pageUrl, product = {} }) {
  const { inventory } = useProductInventoryByHandle({ handle: product.handle });

  const productSchemaMarkup = useMemo(() => {
    return {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: product.title || '',
      image: product.images?.[0]?.src,
      description: product.description,
      brand: {
        '@type': 'Brand',
        name: process.env.NEXT_PUBLIC_SITE_TITLE || '',
      },
      offers:
        product.variants?.length > 1
          ? product.variants.map((variant) => {
              return {
                '@type': 'Offer',
                url: `${pageUrl}?variant=${variant.legacyResourceId}`,
                priceCurrency: variant.currencyCode || 'USD',
                price: variant.price,
                sku: variant.sku || '',
                name: variant.title || '',
                availability: getAvailability({ variant, inventory }),
                itemCondition: 'https://schema.org/NewCondition',
              };
            })
          : {
              '@type': 'Offer',
              url: pageUrl,
              priceCurrency: product.variants?.[0]?.currencyCode || 'USD',
              price: product.priceRange?.min || '',
              sku: product.variants?.[0]?.sku || '',
              name: product.variants?.[0]?.title || '',
              availability: getAvailability({
                variant: product.variants?.[0],
                inventory,
              }),
              itemCondition: 'https://schema.org/NewCondition',
            },
    };
  }, [inventory, pageUrl, product]);

  useEffect(() => {
    if (!inventory) return;
    const script = document.querySelector('#schema-markup-product');
    if (!script) return;
    script.innerHTML = JSON.stringify(productSchemaMarkup);
  }, [inventory]);

  return (
    <>
      <Script
        id="schema-markup-product-breadcrumb"
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: process.env.NEXT_PUBLIC_SITE_TITLE || '',
                item: process.env.SITE_URL || '',
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: product.title || '',
                item: pageUrl,
              },
            ],
          }),
        }}
      />

      <Script
        id="schema-markup-product"
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(productSchemaMarkup),
        }}
      />
    </>
  );
}

ProductSchemaMarkup.displayName = 'ProductSchemaMarkup';
